import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GenericConfirm } from "../../Utils/ConfirmAlert/ConfirmAlerts";
import { ToastFailure, ToastSuccess } from "../../Utils/Toast/ToastMsg";
import "./Attendance.scss";
import EditModal from "./Modals/Edit/Edit";
const Attendances = () => {
  const navigate = useNavigate();
  const [attendancesDetails, setAttendancesDetails] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [captureEditDetails, setCaptureEditDetails] = useState(null);
  const [search, setSearch] = useState("");

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/api/employee`)
      .then((response) => {
        setAttendancesDetails(response.data?.data?.rows ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // delete user
  const handleRemove = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API}/api/employee/${id}`)
      .then((response) => {
        if (response?.data?.status === 200) {
          ToastSuccess(response?.data?.msg);
          getDetails();
        } else {
          ToastFailure("Something went wrong.");
        }
      })
      .catch((error) => {
        console.log(error);
        ToastFailure("Something went wrong.");
      });
  };

  const filteredNames = (attendancesDetails || []).filter((name) => {
    const fullName = `${name.first_name} ${name.last_name}`;
    return fullName.toLowerCase().includes(search.toLowerCase());
  });

  return (
    <>
      <div className="container mt-4">
        <div className="row attendanceSec">
          <div className="col-md-12">
            <div className="d-flex justify-content-end">
              <div>
                <input
                  className="form-control me-2"
                  type="search"
                  placeholder="Search..."
                  aria-label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
            {filteredNames &&
              filteredNames.length > 0 &&
              filteredNames.map((list, i) => {
                const { first_name, last_name, employee_id, id } = list;
                const subjectName =
                  first_name + "_" + last_name + "_" + employee_id;
                return (
                  <div className="AttendanceSection" key={i}>
                    <div className="attendanceDiv">
                      <div className="employeName">
                        <h5>{`${first_name} ${last_name}`} </h5>{" "}
                        <h5>({employee_id})</h5>
                      </div>
                      <div className="LeftSideSection">
                        <div className="employeBtn captureBtn">
                          <button
                            className="LogBtn"
                            onClick={() => {
                              navigate(`/catureFace/${subjectName}`);
                            }}
                          >
                            Capture Face{" "}
                          </button>
                        </div>
                        <div className="employeBtn captureBtn">
                          <button
                            className="LogBtn"
                            onClick={() => {
                              navigate(`/attendances/${employee_id}`);
                            }}
                          >
                            Logs
                          </button>
                        </div>
                        <div className="employeBtn captureBtn">
                          <button
                            type="button"
                            className="LogBtn"
                            onClick={() => {
                              setOpenEditModal(true);
                              setCaptureEditDetails(list);
                            }}
                          >
                            Edit
                          </button>
                        </div>
                        <div className="employeBtn">
                          <button
                            type="button"
                            className="LogBtn"
                            onClick={() => {
                              GenericConfirm({
                                key: employee_id,
                                saveCallback: handleRemove,
                                triggerMsg:
                                  "Are you sure, you want to delete this user?",
                                SaveMsg: "Okay",
                              });
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {openEditModal && (
        <EditModal
          show={openEditModal}
          setShow={setOpenEditModal}
          captureEditDetails={captureEditDetails}
          getDetails={getDetails}
        />
      )}
    </>
  );
};

export default Attendances;
