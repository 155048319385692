import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastFailure, ToastSuccess } from "../../Utils/Toast/ToastMsg";
import "./CaptureImages.scss";
import Loader from "../../Utils/Loader/Loader";
import { isTablet } from "react-device-detect";

const CaptureImages = () => {
  const navigate = useNavigate();
  const params = useParams();
  const videoRef = useRef(null);
  const [images, setImages] = useState([]);
  const [isCapturing, setIsCapturing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subjectName, setSubjectName] = useState("");
  const [capturedImageNo, setCapturedImageNo] = useState(5);
  const [webcamActive, setWebcamActive] = useState(false);

  const imageOption = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 },
    { label: "20", value: 20 },
    { label: "25", value: 25 },
    { label: "30", value: 30 },
  ];

  let handleCapIndex = 0;
  let tempCapturedImg = [];
  useEffect(() => {
    if (params?.id) {
      setSubjectName(params?.id ?? "");
    }
  }, [params?.id]);

  useEffect(() => {
    let streamRef = null;
    const startWebcam = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        streamRef = stream;
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          await videoRef.current.play();
          setIsCapturing(true);
          setWebcamActive(true);
        }
      } catch (error) {
        console.log("error >>", error);
      }
    };

    const stopWebcam = () => {
      if (streamRef) {
        streamRef.getTracks().forEach((track) => track.stop());
        setIsCapturing(false);
        setWebcamActive(false);
      }
    };
    startWebcam();
    return () => {
      stopWebcam();
    };
  }, []);

  // stop capture work
  const stopCapture = async () => {
    setIsCapturing(false);
    const video = document.getElementById("video");
    video.srcObject.getTracks().forEach((track) => track.stop());
    setIsUploading(true);

    const config = {
      params: {
        subject: subjectName,
        // subject: 'test',
        det_prob_threshold: 0.9,
      },
      headers: {
        "content-type": "multipart/form-data",
        "x-api-key": `${process.env.REACT_APP_REGISTRATION_IMAGE_TOKEN}`,
      },
    };

    const promises = images.map(async (image, index) => {
      // const formData = new FormData();
      // const file = new File([image], `image-${index}.png`, { type: 'image/png' });
      // formData.append(`image-${index}`, file);

      const formData = new FormData();
      formData.append(`file`, image);
      setLoading(true);
      return await axios.post(
        `${process.env.REACT_APP_API_IMAGE_CAPTURE}/api/v1/recognition/faces`,
        // `http://192.168.168.11:8000/api/v1/recognition/faces`,
        formData,
        config
      );
    });

    Promise.all(promises)
      .then((responses) => {
        const uploaded = responses.map((response) => response.image_id);
        if (uploaded.length == images.length) {
          // Final callback function to move to another screen
          // console.log("All images have been uploaded.");
          ToastSuccess("All images have been uploaded.");
          navigate(`/attendances`);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("error value >>>", err);
        ToastFailure("Something went wrong, please try again.");
        setLoading(false);
        navigate(`/attendances`);
      });

    setIsUploading(false);
    setImages([]);

    // onUploadProgress: progressEvent => {
    //   setProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100));
    // },
  };

  const handleCapture = async () => {
    if (handleCapIndex < capturedImageNo) {
      setTimeout(function () {
        const video = document.getElementById("video");
        const canvas = document.createElement("canvas");
        // canvas.width = video.videoWidth;
        // canvas.height = video.videoHeight;
        // const context = canvas.getContext("2d");
        // context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // =================
        // const canvasWidth1 = 500;
        // const canvasWidth = 640; // set the desired width of the canvas
        // const canvasHeight = 480; // set the desired height of the canvas
        // canvas.width = canvasWidth;
        // canvas.height = canvasHeight;

        const canvasWidth1 = 500;
        const canvasWidth = 640; // set the desired width of the canvas
        const canvasHeight = 480; // set the desired height of the canvas
        canvas.width = 850;
        canvas.height = canvasHeight;
        const context = canvas.getContext("2d");
        if (isTablet) {
          context.drawImage(video, 80, 0, 320, 480, 250, 0, 340, 480);
        } else {
          context.drawImage(video, 160, 0, 320, 480, 250, 0, 340, 480);
          
        }

        // drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
        // context.drawImage(video, 170, 0, 300, 480, 280, 0, 320, 480); // latest
        // context.drawImage(video, 150, 0, 290, 480, 280, 0, 320, 480); // last working
        // context.drawImage(video, 150, 0, canvasWidth - 160, canvasHeight, 280, 0, canvasWidth1, canvasHeight);
        // context.drawImage(video, 150, 0, 280, 480, 280, 0, 300, 480);
        const dataURI = canvas.toDataURL("image/png");
        const byteString = atob(dataURI.split(",")[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const imageFile = new File([ab], `webcam${handleCapIndex}.png`, {
          type: "image/png",
        });

        // tempCapturedImg.push(dataURI);
        // setImages((images) => [...images, dataURI]);
        tempCapturedImg.push(imageFile);
        setImages((images) => [...images, imageFile]);

        handleCapIndex++;
        if (handleCapIndex < capturedImageNo + 1) {
          handleCapture();
        }
      }, 700);
    }

    // if (tempCapturedImg.length >= capturedImageNo) {
    //   await stopCapture(tempCapturedImg); // (RUN THIS LINE)
    // }
  };

  return (
    <>
      {loading ? (
        <Loader startLoader={loading} />
      ) : (
        <div className="videoSection">
          <div className="captureBtnDiv position">
            <button
              className="captureImageBtn "
              onClick={() => {
                navigate(`/attendances`);
              }}
            >
              Back
            </button>
          </div>
          <div className="VideoSec">
            <div className="VideoBox">
              {webcamActive && (
                <>
                  <div className="leftBox"></div>
                  <div className="rightBox"></div>
                </>
              )}
              <video ref={videoRef} id="video" autoPlay />
            </div>
            <div className="text-center">
              <label>Image Capture</label>
              <select
                name="dropDownImages"
                id="dropDownImages"
                className="mx-3"
                onChange={(e) => setCapturedImageNo(e.target.value)}
                value={capturedImageNo}
              >
                {imageOption.map((list, index) => {
                  return (
                    <option value={list.value} key={index}>
                      {list.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="text-center">
              Number of images captured: {images.length}
            </div>
            {/* <div>Progress: {progress}%</div> */}
            {/* {!isCapturing && <button onClick={startCapture}>Start Capture</button>} */}
            {/* {isCapturing && images.length < capturedImageNo && ( */}
            <div className="text-center captureBtnDiv">
              <button className="captureImageBtn" onClick={handleCapture}>
                Capture Image
              </button>
            </div>
            {/* )} */}
            {images.length > 0 && !isUploading && (
              <button className="captureImageBtn" onClick={stopCapture}>
                Stop Capture and Upload Images
              </button>
            )}
            {isUploading && <div>Uploading images...</div>}
            <div className="container">
              <div className="row">
                <div className="renderImages">
                  {(images || []).length > 0 &&
                    images.map((list, index) => {
                      
                      return (
                        <div className="col-md-2 imageDiv" key={index}>
                          <div
                            className="crossDiv"
                            onClick={() => {
                              setImages((previousValue) =>
                                previousValue.filter((data) => data != list)
                              );
                            }}
                          >
                            <p>x</p>
                          </div>
                          <img
                            src={URL.createObjectURL(list)}
                            alt="capture image"
                            height="150px"
                            width="200px"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CaptureImages;
