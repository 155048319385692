import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../Status.scss";
import editIcon from "../../../Utils/Img/edit.png";
import axios from "axios";
import { ToastFailure, ToastSuccess } from "../../../Utils/Toast/ToastMsg";
function PopupModal(props) {
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(props.wfh_status);
  const handleClose = () => {
    setShow(false);
    setChecked(props.wfh_status)
  };
  const handleShow = () => setShow(true);  
  const handleChange = () => {
    setChecked(!checked);
  };
  const saveButtonHandler = () => {
    const formattedDate = sessionStorage.getItem("date");
    const emp = {
      empId: props.empID,
      dateTime: formattedDate,
      status : checked
    };
    apiHandler(emp);
    props.onSuccess();
    setShow(false);
  };
  const apiHandler = async (emp) => {
    await axios
      .post(`${process.env.REACT_APP_API}/api/addWFH`, emp)
      .then((response) => {
        if (response?.data?.status === 200) {
          ToastSuccess(response?.data?.message);
        }
      })
      .catch((error) => {
        ToastFailure("Request already exists");
      });
  };
  useEffect(() => {
    setChecked(props.wfh_status);
  }, [props.wfh_status]);
  return (
    <>
      <button className="popup" onClick={handleShow}>
        <img src={editIcon} alt="Alt text for the image" height="14px" />
      </button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>WFH Request </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="work-mode">
            <h5 className="text"> Work From Home :</h5>
            <div className="form-check form-switch">
              <input
                className="form-check-input size"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckChecked"
                checked={checked}
                onChange={handleChange}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary text-lg" onClick={handleClose}>
            Close
          </button>
          <div className="saveBtnDiv">
            <button
              className="btn btn-primary disableButton text-lg"
              onClick={saveButtonHandler}
              disabled={props.wfh_status === checked}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default PopupModal;