import axios from "axios";
import { ToastFailure } from "../Utils/Toast/ToastMsg";
export const ChangePassword = async (obj, token) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_API}/api/change/password/admin`,
      obj,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    return await result?.data;
  } catch (error) {
    ToastFailure(error?.response?.data?.message);
  }
};
