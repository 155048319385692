import React from "react";

const CheckOutDetails = (props) => {
  const { firstCheckIn, lastCheckOut } = props;
  return (
    <>
      <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
        {lastCheckOut ? (
          <div className="d-flex justify-content-center gap-1">
            <h5 className="HeaderHeading">CHECKOUT'S</h5>
            <div className="statusCount">{firstCheckIn?.finalResult?.length}</div>
          </div>
        ) : (
          <div className="d-flex justify-content-center gap-1">
            <h5 className="HeaderHeading">CHECKOUT'S</h5>
            <div className="statusCount">
              {firstCheckIn?.checkoutEmp?.length}
            </div>
          </div>
        )}
        {lastCheckOut ? (
          firstCheckIn?.finalResult?.length === 0 ? (
            <div className="dataNotFound">Data Not Found !</div>
          ) : (
            firstCheckIn?.finalResult?.map((item, ind) => {
              return (
                <>
                  <div className="StatusRed" key={ind}>
                    <div className="statusRed">
                      <div>
                        {`${item?.first_name} ${item?.last_name}`}
                      </div>
                      <div className="d-flex flex-column">
                        <sapn className="test">
                          {item?.checkin_times?.length ===
                          item?.checkout_times?.length
                            ? item?.checkout_times[
                                item?.checkout_times?.length - 1
                              ]?.split(" ")[1] +
                              " " +
                              item?.checkout_times[
                                item?.checkout_times?.length - 1
                              ]?.split(" ")[2]
                            : "-- : --"}
                        </sapn>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          )
        ) : firstCheckIn?.checkoutEmp?.length === 0 ? (
          <div className="dataNotFound">Data Not Found !</div>
        ) : (
          firstCheckIn?.checkoutEmp?.map((item, ind) => {
            return (
              <>
                <div className="StatusRed" key={ind}>
                  <div className="statusRed">
                    <div>
                      {`${item?.first_name} ${item?.last_name}`}
                    </div>
                    <div className="d-flex flex-column">
                      <sapn className="test">
                        {item?.punch_time?.split(" ")[1] +
                          " " +
                          item?.punch_time?.split(" ")[2]}
                      </sapn>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        )}
      </div>
    </>
  );
};

export default CheckOutDetails;
