import axios from "axios";
import { useEffect, useState } from "react";
import "./request.style.scss";
import { ToastFailure, ToastSuccess } from "../../Utils/Toast/ToastMsg";
import moment from "moment";

const Requests = () => {
  const [requestData, setRequestData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [isAccept, setIsAccept] = useState(null);
  const [recordId, setRecordId] = useState(null);
  const [error, setError] = useState(null);
  const [ran, setRan] = useState("id" + Math.random().toString(16).slice(2));
  const [description, setDescription] = useState({
    description: "",
  });

  useEffect(() => {
    getAllRequest();
    getAllSummaryRequest();
  }, []);
  // get all request
  const getAllRequest = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/api/changeRequest`)
      .then((response) => {
        setRequestData(response.data.data);
      });
  };

  // value.description
  const submitEdit = async (e) => {
    e.preventDefault();
      if (description?.description === "") {
        setError("Description is required");
        return;
      } else if (description?.description?.length < 6) {
        setError("Description is too short - should be 6 chars minimum.");
        return;
      }
    setRan("id" + Math.random().toString(16).slice(2))
    await axios
      .put(`${process.env.REACT_APP_API}/api/changeRequest/edit`, {
        reason: description?.description,
        id: recordId,
        status: isAccept,
      })
      .then((response) => {
        if (response?.data?.status === (200 || 201)) {
          getAllRequest();
          getAllSummaryRequest();
          ToastSuccess("Update status sent to user.");
        } else {
          ToastFailure("Something went wrong.");
        }
      })
      .catch((error) => {
        ToastFailure("Something went wrong.");
      });
    setDescription({ description: "" });
  };

  // get all Summary Data
  const getAllSummaryRequest = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/api/changeRequest?summary=false`)
      .then((response) => {
        setSummaryData(response.data.data);
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value === "" || value?.length === 0) {
      setError("Description is required");
    } else if (value?.length < 6) {
      setError("Description is too short - should be 6 chars minimum.");
    } else {
      setError(null);
    }

    setDescription({ [name]: value });
  };
  return (
    <>
      <div className="container AccordionSection mt-4">
        <div className="row">
          <div className="col-md-12">
            <div className="tabsSec">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item  employeBtn" role="presentation">
                  <button
                    className="nav-link active LogBtn"
                    id="home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#home"
                    type="button"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Request
                  </button>
                </li>
                <li className="nav-item employeBtn" role="presentation">
                  <button
                    className="nav-link LogBtn"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Summary
                  </button>
                </li>
              </ul>
            </div>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active p-4"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="AttendanceSection summaryContent">
                  <div className="accordion" id="accordionExample">
                    {requestData?.length > 0 && requestData
                      ? requestData.map((key, i) => {
                          return (
                            <div className="accordion-item" key={i+ran}>
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapseOne${i+ran}`}
                                  aria-expanded="true"
                                  aria-controls={`collapseOne${i+ran}`}
                                >
                                  <div className="timeBtn requestAccordian">
                                    <div className="requestAccordianKey">
                                      <h5 className="empNameId">{`${key.Employee.first_name} ${key.Employee.last_name}`}</h5>
                                      <h5 className="empNameId">
                                        ({`${key.assignee_id}`})
                                      </h5>
                                    </div>
                                    <div className="dateSection">
                                      Request Type:
                                      <b>{`${
                                        key.action === "UPDATE"
                                          ? " Edit"
                                          : " Delete"
                                      }`}</b>
                                    </div>
                                  </div>
                                </button>
                              </h2>
                              <div
                                id={`collapseOne${i+ran}`}
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div>
                                    <h3>Requested:</h3>
                                  </div>
                                  <div className="">
                                    {key.action === "DELETE" ? (
                                      <p className="title">
                                        User requested to delete this entry{" "}
                                        {key.punch_status === true
                                          ? "Punch-In"
                                          : "Punch-Out"}
                                        :{" "}
                                        {moment(
                                          `${key.previous_punch_timestamp}`
                                        ).format("DD-MM-YYYY HH:mm A")}
                                      </p>
                                    ) : (
                                      <div className="punchTime">
                                        <p className="title">
                                          {key.punch_status === true
                                            ? "Previous Punch-In"
                                            : "Previous Punch-Out"}
                                          :{" "}
                                          {moment(
                                            `${key.previous_punch_timestamp}`
                                          ).format("DD-MM-YYYY HH:mm A")}
                                        </p>
                                        <p className="title">
                                          {key.punch_status === true
                                            ? "Requested Punch-In"
                                            : "Requested Punch-Out"}
                                          :{" "}
                                          {moment(
                                            `${key.requested_punch_timestamp}`
                                          ).format("DD-MM-YYYY HH:mm A")}{" "}
                                        </p>
                                      </div>
                                    )}

                                    <p className="title">
                                      Request Description:
                                    </p>
                                    <p className="description">{`${key.request_reason}`}</p>
                                  </div>
                                  <hr />
                                  <div>
                                    <h3>Acceptance:</h3>
                                  </div>
                                  <div>
                                    {" "}
                                    <div className="mb-3 mt-2">
                                      <form onSubmit={submitEdit}>
                                        <div className="form-group mb-2">
                                          <label
                                            htmlFor="username"
                                            className="mb-2"
                                          >
                                            Statement:
                                          </label>
                                          <textarea
                                            type="text"
                                            id="username"
                                            name="description"
                                            onChange={handleChange}
                                            value={description?.description}
                                            className="form-control"
                                            rows={5}
                                          ></textarea>
                                          {error?.length > 0 && (
                                            <div className="requestError">
                                              {error}
                                            </div>
                                          )}
                                        </div>
                                        <div className="footerBtn mt-3">
                                          <div className="employeBtn FoterEmployeSec">
                                            <button
                                              type="submit"
                                              className="btn btn-block LogBtn"
                                              onClick={() => {
                                                setIsAccept(true);
                                                setRecordId(key.id);
                                              }}
                                            >
                                              Accept
                                            </button>
                                          </div>
                                          <div className="employeBtn FoterEmployeSec">
                                            <button
                                              type="submit"
                                              className="btn btn-block LogBtn"
                                              onClick={() => {
                                                setIsAccept(false);
                                                setRecordId(key.id);
                                              }}
                                            >
                                              Decline
                                            </button>
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : "Request not found."}
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade p-4"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="AttendanceSection summaryContent">
                  <div className="accordion" id="accordionExample">
                    {summaryData?.length > 0 && summaryData
                      ? summaryData.map((key, ind) => {
                          return (
                            <div className="accordion-item" key={ind + "100"}>
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target={`#collapseOne${ind + "100"}`}
                                  aria-expanded="true"
                                  aria-controls={`collapseOne${ind + "100"}`}
                                >
                                  <div className="timeBtn requestAccordian">
                                    <div className="requestAccordianKey">
                                      {" "}
                                      <h5 className="empNameId">{`${key.Employee.first_name} ${key.Employee.last_name}`}</h5>
                                      &nbsp;
                                      <h5 className="empNameId">
                                        ({`${key.assignee_id}`})
                                      </h5>
                                    </div>
                                    <div className="dateSection">
                                      Request Type: &nbsp;
                                      <b>{`${
                                        key.action === "UPDATE"
                                          ? "Edit"
                                          : "Delete"
                                      }`}</b>
                                    </div>
                                  </div>
                                </button>
                              </h2>
                              <div
                                id={`collapseOne${ind + "100"}`}
                                className="accordion-collapse collapse"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div>
                                    <h3>Requested:</h3>
                                  </div>
                                  <div className="">
                                    {key.action === "DELETE" ? (
                                      <p className="title">
                                        User requested to delete this entry{" "}
                                        {moment(
                                          `${key.previous_punch_timestamp}`
                                        ).format("LT")}
                                      </p>
                                    ) : (
                                      <div className="punchTime">
                                        <p className="title">
                                          {key.punch_status === true
                                            ? "Previous Punch-In"
                                            : "Previous Punch-Out"}
                                          :{" "}
                                          {moment(
                                            `${key.previous_punch_timestamp}`
                                          ).format("DD-MM-YYYY HH:mm A")}
                                        </p>
                                        <p className="title">
                                          {key.punch_status === true
                                            ? "Requested Punch-In"
                                            : "Requested Punch-Out"}{" "}
                                          :{" "}
                                          {moment(
                                            `${key.requested_punch_timestamp}`
                                          ).format("DD-MM-YYYY HH:mm A")}{" "}
                                        </p>
                                      </div>
                                    )}

                                    <p className="title">
                                      Request Description:
                                    </p>
                                    <p className="description">{`${key.request_reason}`}</p>
                                  </div>
                                  <hr />
                                  <div>
                                    <h3>Acceptance:</h3>
                                  </div>
                                  <div> </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : "Summary not found."}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Requests;
