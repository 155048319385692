import axios from "axios";
export const GetEmployeeFirstCheckInTime = async (obj) => {
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API}/api/report/v2/getEmployeeFirstCheckINTime`,
      { params: { from: obj } }
    );
    return await result?.data;
  } catch (err) {
    console.log("error found", err);
  }
};
