import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../AttendanceLog.scss";
import DatePicker from "react-datepicker";
import "./PunchModal.scss";
import { useParams } from "react-router-dom";
import moment from "moment";
import { ToastFailure, ToastSuccess } from "../../../../Utils/Toast/ToastMsg";
import axios from "axios";
function AddPunch(props) {
  const params = useParams();
  const defaultOptions = [
    { label: "Punch-In", value: true },
    { label: "Punch-Out", value: false },
  ];
  const [show, setShow] = useState(false);
  const [punchStatus, setPunchStatus] = useState(null);
  const [options] = useState(defaultOptions);
  const handleClose = () => {
    setStartDate()
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [startDate, setStartDate] = useState(new Date());
  const saveButtonHandler = async () => {
    const year = startDate.getFullYear();
    const month = (startDate.getMonth() + 1).toString().padStart(2, "0");
    const day = startDate.getDate().toString().padStart(2, "0");
    const hours = startDate.getHours().toString().padStart(2, "0");
    const minutes = startDate.getMinutes().toString().padStart(2, "0");
    const seconds = startDate.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const obj = {
      eId: params?.id,
      punchTime: formattedDate,
      punchStatus: punchStatus,
    };
    axios
      .post(`${process.env.REACT_APP_API}/api/checkInOut`, obj)
      .then((response) => {
        ToastSuccess(response?.data?.Message);
        props.onSuccess(obj.eId);
      })
      .catch((err) => {
        ToastFailure(err?.response?.data);
      });
    setPunchStatus(null);
    setStartDate();
    handleClose();
  };
  const selectChangeHandler = (e) => {
    if (e.target.value) {
      setPunchStatus(e.target.value);
    } else {
      setPunchStatus(null);
    }
  };
  return (
    <>
      <div className="employeBtn">
        <button className="LogBtn" onClick={handleShow}>
          Request
        </button>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-md-6 ms-3">
                <div className="typeSection">
                  <label htmlFor="in-out">Type :</label>
                  <select className="select" onChange={selectChangeHandler}>
                    <option value="">Select One</option>
                    {options.map((list, i) => (
                      <option value={list.value} key={i}>
                        {list.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="dateSection">
                  <label htmlFor="datepicker">Log Date & Time : </label>
                  <DatePicker
                    name="datePicker"
                    showTimeSelect
                    selected={startDate}
                    onChange={(date) => {
                      setStartDate(date);
                    }}
                    timeIntervals={5}
                    autoComplete="off"
                    dateFormat="dd/MM/y hh:mm aa"
                    placeholderText="Select Time & Date"
                    maxDate={new Date()}
                    minTime={new Date().setHours(0, 0, 0, 0)}
                    maxTime={
                      moment(startDate).format("l") ===
                      moment(new Date()).format("l")
                        ? Date.now()
                        : moment(startDate).endOf("day").toDate()
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary largeFont" onClick={handleClose}>
            Close
          </button>
          <div className="saveBtnDiv">
            <button
              className="btn btn-primary disableButton largeFont"
              onClick={saveButtonHandler}
              disabled={punchStatus ? false : true}
            >
              Save
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AddPunch;
