import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./AttendanceLog.scss";
import { ToastFailure, ToastSuccess } from "../../Utils/Toast/ToastMsg";
import crossIcon from "../../Utils/Img/close.png";
import editIcon from "../../Utils/Img/edit.png";
import { GenericConfirm } from "../../Utils/ConfirmAlert/ConfirmAlerts";
import EditLogsModal from "./Modals/EditLogsModal";
import AddPunch from "./Modals/Punch In-Out/PunchModal";
import Loader from "../../Utils/Loader/Loader";

const AttendanceLogs = () => {
  const params = useParams();
  const [logs, setLogs] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [captureEditDetails, setCaptureEditDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [empName, setEmpName] = useState("");
  useEffect(() => {
    if (params?.id) {
      getCheckinDetails(params?.id);
    }
  }, [params?.id]);

  const getCheckinDetails = async (_id) => {
    setIsLoading(true);
    await axios
      // .get(`${process.env.REACT_APP_BASE_URL_staging}admin/terms-and-rules`)
      .get(`${process.env.REACT_APP_API}/api/checkin/${_id}`)
      .then((response) => {
        const newArray = (response.data?.data?.rows || []).map((res) => {
          const date = moment(res.punch_timestamp).format("LL");
          const time = moment(res.punch_timestamp).format("LT");
          return { ...res, date: date, time: time };
        });
        const finalData = groupItems(newArray, "date");
        setLogs(finalData);
        setEmpName(
          finalData[Object.keys(finalData)[0]][0]?.Employee?.full_name
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  // delete timing
  const handleRemove = async (id) => {
    await axios
      .delete(`${process.env.REACT_APP_API}/api/checkin/${id}`)
      .then((response) => {
        if (response?.data?.status === 200) {
          ToastSuccess(response?.data?.msg);
          getCheckinDetails(params?.id);
        } else {
          ToastFailure("Something went wrong.");
        }
      })
      .catch((error) => {
        console.log(error);
        ToastFailure("Something went wrong.");
      });
  };

  function groupItems(array, property) {
    return array.reduce(function (groups, item) {
      const name = item[property];
      const group = groups[name] || (groups[name] = []);
      group.push(item);
      return groups;
    }, {});
  }

  return (
    <>
      {isLoading ? (
        <Loader startLoader={isLoading} />
      ) : (
        <div className="AccordionSection mt-4">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="employeBtnBack">
                  <div className="employeBtn">
                    <button
                      className="LogBtn"
                      onClick={() => {
                        // navigate(`/attendances`);
                        window.history.back();
                      }}
                    >
                      Back
                    </button>
                  </div>
                  <div className="employeNameHeading">
                    <h2>{empName}</h2>
                  </div>
                  <AddPunch onSuccess={getCheckinDetails} />
                </div>
                <div className="accordion" id="accordionExample">
                  {Object.keys(logs).length > 0 &&
                    Object.keys(logs).map((key, i) => {
                      return (
                        <div className="accordion-item" key={i}>
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${i}`}
                              aria-expanded="true"
                              aria-controls={`collapseOne${i}`}
                            >
                              <div className="timeBtn">
                                <div>{key}</div>
                                {logs[key][0]?.total_working?.hours && (
                                  <div className="dateSection1 d-flex flex-row align-items-center">
                                    {logs[key][0]?.total_working?.hours &&
                                      logs[key][0]?.total_working?.hours +
                                        "hr"}{" "}
                                    &nbsp;
                                    {logs[key][0]?.total_working?.minutes &&
                                      logs[key][0]?.total_working?.minutes +
                                        "min"}
                                    &nbsp;
                                    {logs[key][0]?.total_working?.seconds &&
                                      logs[key][0]?.total_working?.seconds +
                                        "sec"}{" "}
                                  </div>
                                )}
                                <div>
                                  {logs[key][logs[key].length - 1]
                                    ?.punch_status &&
                                    moment(
                                      logs[key][logs[key].length - 1]
                                        ?.punch_timestamp
                                    ).format("HH:mm") > "10:10" && (
                                      <h5 className="lateIn">Late</h5>
                                    )}
                                </div>
                              </div>
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${i}`}
                            className="accordion-collapse collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <ul>
                                {logs[key].map((list, i) => {
                                  const { punch_status, time, id } = list;
                                  return (
                                    <li key={i}>
                                      {punch_status
                                        ? "Punch-In,"
                                        : "Punch-Out,"}{" "}
                                      <b>{time}</b>
                                      <span
                                        className="ml_24 cursorPointer"
                                        onClick={() => {
                                          setOpenEditModal(true);
                                          setCaptureEditDetails(list);
                                        }}
                                      >
                                        <img
                                          src={editIcon}
                                          alt="Alt text for the image"
                                          height="14px"
                                        />
                                      </span>
                                      <span
                                        className="ml_12 cursorPointer"
                                        onClick={() => {
                                          GenericConfirm({
                                            key: id,
                                            saveCallback: handleRemove,
                                            triggerMsg:
                                              "Are you sure, you want to delete this entry?",
                                            SaveMsg: "Okay",
                                          });
                                        }}
                                      >
                                        <img
                                          src={crossIcon}
                                          alt="Alt text for the image"
                                          height="12px"
                                        />
                                      </span>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openEditModal && (
        <EditLogsModal
          show={openEditModal}
          setShow={setOpenEditModal}
          captureEditDetails={captureEditDetails}
          getDetails={() => getCheckinDetails(params?.id)}
        />
      )}
    </>
  );
};

export default AttendanceLogs;
