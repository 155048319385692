import { useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { ToastFailure, ToastSuccess } from "../../../Utils/Toast/ToastMsg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import "./editPublicModel.style.scss";

function EditPublicLogsModal(props) {
  const { show, setShow, captureEditDetails, type } = props;
  const { punch_timestamp } = captureEditDetails;
  const [logDate, setLogDate] = useState(new Date(punch_timestamp));
  const { id } = useParams();

  const handleClose = () => setShow(false);

  const submitEdit = async ({ description }) => {
    await axios
      .post(
        `${process.env.REACT_APP_API}/api/changeRequest/${captureEditDetails.id}`,
        {
          assignee_id: id,
          action: type, //DELETE/UPDATE
          reason: description,
          requested_punch_timestamp: logDate,
        }
      )
      .then((response) => {
        if (response?.data?.status == (200 || 201)) {
          ToastSuccess("Request has been send.");
          // getDetails();
          handleClose();
        } else {
          ToastFailure("Something went wrong.");
        }
      })
      .catch((error) => {
        ToastFailure("Something went wrong.");
      });
  };
  // login validation
  const UpdateSchema = Yup.object().shape({
    description: Yup.string()
      .required("Description is required")
      .min(15, "Description is too short - should be 15 chars minimum."),
  });
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {type === "UPDATE" ? "Edit Logs" : "Delete Logs"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            {type == "DELETE" ? (
              ""
            ) : (
              <div>
                <label className="mb-2">Log Date & Time:</label>
                <DatePicker
                  name="ticketAfterDate"
                  showTimeSelect
                  selected={logDate}
                  onChange={(date) => {
                    setLogDate(date);
                  }}
                  timeIntervals={5}
                  dateFormat="dd/MM/y hh:mm aa"
                  placeholderText="Select Time & Date"
                  maxDate={new Date()}
                  minTime={new Date().setHours(0, 0, 0, 0)}
                  maxTime={
                    moment(logDate).format("l") ==
                    moment(new Date()).format("l")
                      ? Date.now()
                      : moment(logDate).endOf("day").toDate()
                  }
                />
              </div>
            )}
            <div className="mb-3 mt-2">
              <Formik
                initialValues={{
                  description: "",
                }}
                enableReinitialize={true}
                validationSchema={UpdateSchema}
                onSubmit={(values) => {
                  submitEdit(values);
                }}
              >
                {({ errors, touched, handleChange, values }) => (
                  <Form>
                    <div className="form-group mb-2">
                      <label htmlFor="username">Description:</label>
                      <textarea
                        type="text"
                        id="username"
                        name="description"
                        onChange={handleChange}
                        value={values.description}
                        className="form-control"
                        rows={5}
                      ></textarea>
                      {errors.description && touched.description ? (
                        <div className="errorMsgs">{errors.description}</div>
                      ) : null}
                    </div>
                    <div className="footerBtn">
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditPublicLogsModal;
