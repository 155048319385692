import React from "react";
import DataTable from "react-data-table-component";

export default function ReactDataTable(props) {
  const { attendanceData } = props;

  const column = [
    {
      name: "S.no",
      selector: (row) => row.s_no,
    },
    {
      name: "First Name",
      selector: (row) => row.first_name,
    },
    {
      name: "Last Name",
      selector: (row) => row.last_name,
    },
    {
      name: "Emp ID",
      selector: (row) => row.employee_id,
    },
    {
      name: "Check In",
      selector: (row) => row.checkin_time,
    },
    {
      name: "Check Out",
      selector: (row) => row.checkout_time,
    },
    {
      name: "total Hours",
      selector: (row) => <b>{row.total_hours}</b>,
    },
  ];
  return (
    <div>
      {" "}
      <DataTable
        size="xl"
        columns={column}
        data={attendanceData}
        pagination
        fixedHeader
      />
    </div>
  );
}
