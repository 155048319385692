import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./ConfirmAlerts.style.scss";

export const GenericConfirm = ({
    key,
    saveCallback,
    triggerMsg,
    SaveMsg,
    cancelCallback,
  }) => {
    confirmAlert({
      message: triggerMsg,
      buttons: [
        {
          label: SaveMsg || "Okay",
          onClick: () => saveCallback(key),
        },
        {
          label: "Cancel",
          onClick: () => cancelCallback && cancelCallback(),
        },
      ],
    });
  };