import React, { useEffect, useState } from "react";
import "./Sidebar.scss";
import {
  RiLayoutGridFill,
  RiChat4Fill,
  RiTeamFill,
  RiPieChart2Fill,
  RiSettings2Fill
} from "react-icons/ri";
import { FaRegUser } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
const Sidebar = ({ children, setIsUser, isUser }) => {
  const sidebarCollapsed = sessionStorage.getItem("sidebar-collapsed");
  const [isExpanded, setIsExpanded] = useState(sidebarCollapsed ? false : true);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const navRef = () => {
    if (windowSize[0] < 1200) {
      setIsExpanded(false);
      sessionStorage.setItem("sidebar-collapsed", true);
    }
  };
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const handleToggler = () => {
    if (isExpanded) {
      setIsExpanded(false);
      sessionStorage.setItem("sidebar-collapsed", true);
      return;
    }
    setIsExpanded(true);
    sessionStorage.removeItem("sidebar-collapsed");
  };

  return (
    <>
      {isUser && (
        <Navbar
          windowSize={windowSize}
          onClick={handleToggler}
          setIsUser={setIsUser}
        />
      )}
      <div className="d-flex flex-column">
        {isUser && (
          <div className=" sidebar-section-1">
            <div className={isExpanded ? "Sidebar" : "Sidebar collapsed"}>
              <div className="sidebar-items">
                <NavLink
                  to="/status"
                  className={({ isActive }) =>
                    isActive ? "bgRed navlink" : "navlink"
                  }
                  onClick={navRef}
                >
                  <div className="item">
                    <RiLayoutGridFill className="sidebar-icon" />
                    <span className="sidebar-text">Dashboard</span>
                  </div>
                </NavLink>

                <NavLink
                  to="/registration"
                  className={({ isActive }) =>
                    isActive ? "bgRed navlink" : "navlink"
                  }
                  onClick={navRef}
                >
                  <div className="item">
                    <FaRegUser className="sidebar-icon" />
                    <span className="sidebar-text">Register</span>
                  </div>
                </NavLink>

                <NavLink
                  to="/requests"
                  className={({ isActive }) =>
                    isActive ? "bgRed navlink" : "navlink"
                  }
                  onClick={navRef}
                >
                  <div className="item">
                    <RiPieChart2Fill className="sidebar-icon" />
                    <span className="sidebar-text">Requests</span>
                  </div>
                </NavLink>

                <NavLink
                  to="/attendances"
                  className={({ isActive }) =>
                    isActive ? "bgRed navlink" : "navlink"
                  }
                  onClick={navRef}
                >
                  <div className="item">
                    <RiTeamFill className="sidebar-icon" />
                    <span className="sidebar-text">Attendance</span>
                  </div>
                </NavLink>

                <NavLink
                  to="/reports"
                  className={({ isActive }) =>
                    isActive ? "bgRed navlink" : "navlink"
                  }
                  onClick={navRef}
                >
                  <div className="item">
                    <RiChat4Fill className="sidebar-icon" />
                    <span className="sidebar-text">Reports</span>
                  </div>
                </NavLink>
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    isActive ? "bgRed navlink" : "navlink"
                  }
                  onClick={navRef}
                >
                  <div className="item">
                    <RiSettings2Fill className="sidebar-icon" />
                    <span className="sidebar-text">Settings</span>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        )}
        <div
          className={`${
            isExpanded
              ? `${isUser && "sidebar-section-2"}`
              : `${isUser && "sidebar-expand-section-2"}`
          }`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
