import TechvalensLogo from "../../src/Utils/Img/Techvalens_logo_g-1.png";
import techvalensIcon from "../../src/Utils/Img/Techvalens_icon_a-1.png";
import { useLocation, useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import "./Navbar.scss";
const Navbar = ({ onClick, windowSize, setIsUser }) => {
  const location = useLocation();
  let currPage = "";
  if (location.pathname.includes("/attendance")) {
    currPage = "Attendance";
  } else if (location.pathname.includes("/status")) {
    currPage = "Dashboard";
  } else if (location.pathname.includes("/registration")) {
    currPage = "Register";
  } else if (location.pathname.includes("/requests")) {
    currPage = "Requests";
  } else if (location.pathname.includes("/reports")) {
    currPage = "Reports";
  } else if (location.pathname.includes("/profile")) {
    currPage = "Settings";
  }
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const isAuthenticated = Boolean(token);
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light position-fixed w-100">
        <div className="container-fluid">
          <div className="d-flex flex-row gap-3">
            {windowSize[0] > 1200 ? (
              <div className="d-flex techvalensIcon1">
                <img className="TVLogo" src={TechvalensLogo} alt="" />
              </div>
            ) : (
              <div className="d-flex techvalensIcon2">
                <img src={techvalensIcon} alt="" />
              </div>
            )}
            <div className="d-flex justify-content-center align-items-center">
              <span className="navbar-toggler-icon" onClick={onClick}></span>
            </div>
          </div>
          <ul className="navbar-nav m-auto mb-2 mb-lg-0">
            <h4 className="attendenceHeading">
              Attendance Management System - {currPage}
            </h4>
          </ul>

          <div className="">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {isAuthenticated && isAuthenticated ? (
                  <>
                    {windowSize[0] < 1190 ? (
                      <FiLogOut
                        onClick={() => {
                          sessionStorage.removeItem("token");
                          setIsUser(false);
                          sessionStorage.removeItem("isLogin");
                          navigate(`/`);
                        }}
                      />
                    ) : (
                      <div className="LogoutBtn">
                        <button
                          type="button"
                          className="btn LogOutBtn"
                          onClick={() => {
                            sessionStorage.removeItem("token");
                            setIsUser(false);
                            sessionStorage.removeItem("isLogin");
                            sessionStorage.removeItem("email");
                            sessionStorage.removeItem("date");
                            navigate(`/`);
                          }}
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="d-flex">
                    <img src={techvalensIcon} alt="" />
                  </div>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
