import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import AttendanceLogs from "../Pages/Attendances/AttendanceLogs";
import Attendances from "../Pages/Attendances/Attendances";
import AttendancePublicLogs from "../Pages/PublicAttendance/AttendancePublicLogs";
import Registration from "../Pages/Auth/Registration";
import CaptureImages from "../Pages/CaptureImages/CaptureImages";
import Reports from "../Pages/Reports/Reports";
import Login from "../Pages/Auth/Login";
import Requests from "../Pages/Requests/Request";
import { BrowserRouter } from "react-router-dom";
import Status from "../Pages/Status/Status";
import Sidebar from "../Sidebar/Sidebar";
import { useState } from "react";
import EditProfile from "../Pages/Profile/EditProfile";

const AppRouter = () => {
  const isLogin = sessionStorage.getItem("isLogin");
  const [isUser, setIsUser] = useState(isLogin ? true : false);
  return (
    <BrowserRouter>
      <Sidebar isUser={isUser} setIsUser={setIsUser}>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login setIsUser={setIsUser} />
              </PublicRoute>
            }
          />
          <Route
            path="/attendances"
            element={
              <PrivateRoute>
                <Attendances />
              </PrivateRoute>
            }
          />
          <Route
            path="/registration"
            element={
              <PrivateRoute>
                <Registration />
              </PrivateRoute>
            }
          />
          <Route
            path="/attendances/:id"
            element={
              <PrivateRoute>
                <AttendanceLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/catureFace/:id"
            element={
              <PrivateRoute>
                <CaptureImages />
              </PrivateRoute>
            }
          />
          <Route
            path="/status"
            element={
              <PrivateRoute>
                <Status />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/requests"
            element={
              <PrivateRoute>
                <Requests />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <EditProfile/>
              </PrivateRoute>
            }
          />
          <Route
            path="/employeeRecord/:id"
            element={
              <PublicRoute>
                <AttendancePublicLogs />
              </PublicRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Sidebar>
    </BrowserRouter>
  );
};

function PublicRoute({ children }) {
  const token = sessionStorage.getItem("token");
  const isAuthenticated = Boolean(token);
  return isAuthenticated ? <Navigate to="/status" /> : <>{children}</>;
}

function PrivateRoute({ children }) {
  const token = sessionStorage.getItem("token");
  const isAuthenticated = Boolean(token);
  return isAuthenticated ? <>{children}</> : <Navigate to="/" />;
}

export default AppRouter;
