import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./AttendancePublicLogs.scss";
import Header from "../../Components/Header/Header";
import crossIcon from "../../Utils/Img/close.png";
import editIcon from "../../Utils/Img/edit.png";
import EditPublicLogsModal from "./Modal/EditPublicLogsModal";
import Loader from "../../Utils/Loader/Loader";

const AttendancePublicLogs = () => {
  const params = useParams();
  const [logs, setLogs] = useState({});
  const [openEditModal, setOpenEditModal] = useState(false);
  const [captureEditDetails, setCaptureEditDetails] = useState(null);
  const [employeeName, setEmployeeName] = useState(null);
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (params?.id) {
      getCheckinDetails(params?.id);
    }
  }, [params?.id]);

  const getCheckinDetails = async (_id) => {
    setIsLoading(true);
    await axios
      // .get(`${process.env.REACT_APP_BASE_URL_staging}admin/terms-and-rules`)
      .get(`${process.env.REACT_APP_API}/api/checkin/${_id}`)
      .then((response) => {
        setEmployeeName(
          response.data?.data?.rows[0].Employee.full_name ?? null
        );
        const newArray = (response.data?.data?.rows || []).map((res) => {
          const date = moment(res.punch_timestamp).format("LL");
          const time = moment(res.punch_timestamp).format("LT");
          return { ...res, date: date, time: time };
        });
        const finalData = groupItems(newArray, "date");
        setLogs(finalData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  function groupItems(array, property) {
    return array.reduce(function (groups, item) {
      const name = item[property];
      const group = groups[name] || (groups[name] = []);
      group.push(item);
      return groups;
    }, {});
  }

  return (
    <>
      {isLoading ? (
        <Loader startLoader={isLoading} />
      ) : (
        <>
          <Header />
          <div className="AccordionSection PublicEmp">
            <div className="container">
              <div className="row">
                <div className="employeNameHeading">
                  <h2>{employeeName ?? ""}</h2>
                </div>
                <div className="col-md-12">
                  <div className="accordion" id="accordionExample">
                    {Object.keys(logs).length > 0 &&
                      Object.keys(logs).map((key, i) => {
                        return (
                          <div className="accordion-item" key={i}>
                            <h2 className="accordion-header" id="headingOne">
                              <button
                                className="accordion-button"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={`#collapseOne${i}`}
                                aria-expanded="true"
                                aria-controls={`collapseOne${i}`}
                              >
                                <div className="timeBtn">
                                  <div>{key}</div>
                                  {logs[key][0]?.total_working?.hours && (
                                    <div className="dateSection1 d-flex flex-row align-items-center">
                                      {logs[key][0]?.total_working?.hours &&
                                        logs[key][0]?.total_working?.hours +
                                          "hr"}{" "}
                                      &nbsp;
                                      {logs[key][0]?.total_working?.minutes &&
                                        logs[key][0]?.total_working?.minutes +
                                          "min"}
                                      &nbsp;
                                      {logs[key][0]?.total_working?.seconds &&
                                        logs[key][0]?.total_working?.seconds +
                                          "sec"}{" "}
                                    </div>
                                  )}
                                  <div>
                                    {logs[key][logs[key].length - 1]
                                      ?.punch_status &&
                                      moment(
                                        logs[key][logs[key].length - 1]
                                          ?.punch_timestamp
                                      ).format("HH:mm") > "10:10" && (
                                        <h5 className="lateIn">Late</h5>
                                      )}
                                  </div>
                                </div>
                              </button>
                            </h2>
                            <div
                              id={`collapseOne${i}`}
                              className="accordion-collapse collapse"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div className="accordion-body">
                                <ul>
                                  {logs[key].map((list, i) => {
                                    const { punch_status, time, id } = list;
                                    return (
                                      <li key={i}>
                                        {punch_status
                                          ? "Punch-In,"
                                          : "Punch-Out,"}{" "}
                                        <b>{time}</b>
                                        <span
                                          className="ml_24 cursorPointer"
                                          onClick={() => {
                                            setType("UPDATE");
                                            setOpenEditModal(true);
                                            setCaptureEditDetails(list);
                                          }}
                                        >
                                          <img
                                            src={editIcon}
                                            alt="Alt text for the image"
                                            height="14px"
                                          />
                                        </span>
                                        <span
                                          className="ml_12 cursorPointer"
                                          onClick={() => {
                                            setType("DELETE");
                                            setOpenEditModal(true);
                                            setCaptureEditDetails(list);
                                          }}
                                        >
                                          <img
                                            src={crossIcon}
                                            alt="Alt text for the image"
                                            height="12px"
                                          />
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {openEditModal && (
        <EditPublicLogsModal
          show={openEditModal}
          setShow={setOpenEditModal}
          type={type}
          captureEditDetails={captureEditDetails}
          getDetails={() => getCheckinDetails(params?.id)}
        />
      )}
    </>
  );
};

export default AttendancePublicLogs;
