import axios from "axios";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactDataTable from "../../../../Components/React-Data-Table/ReactDataTable";
import { ToastFailure } from "../../../../Utils/Toast/ToastMsg";
import "./preview.scss";
function  PreviewModal(props) {
  const { show, setShow, startDate, endDate, selectedOption } = props;
  const [attendanceData, setAttendanceData] = useState([]);
  useEffect(() => {
    handlePreview();
  }, [selectedOption]);

  const handleClose = () => setShow(false);

  const handlePreview = async () => {

    if (startDate && !endDate) {
      setShow(true);
      await axios
        .get(
          `${process.env.REACT_APP_API}/api/report/employeesAttendancePreview?from=${startDate}&to=${startDate}`,
          { params: { empId: selectedOption?.value } }
        )
        .then((response) => {
          setAttendanceData(response.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (startDate && endDate) {
      setShow(true);
      await axios
        .get(
          `${process.env.REACT_APP_API}/api/report/employeesAttendancePreview?from=${startDate}&to=${endDate}`,
          { params: { empId: selectedOption?.value } }
        )
        .then((response) => {
          setAttendanceData(response.data?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      ToastFailure("Please select start date and end date.");
    }
  };

  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Attendance Table</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ReactDataTable attendanceData={attendanceData} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PreviewModal;
