import React, { useEffect, useState } from "react";
import "./Status.scss";
import CheckinAccordian from "./CheckinAccordian";
import { GetEmployeeFirstCheckInTime } from "../../services/GetEmployeeFirstCheckInTime";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { week, month } from "../../Constant";
import moment from "moment/moment";
import Accordion from "react-bootstrap/Accordion";
import CheckOutDetails from "./CheckOutDetails";
import PopupModal from "./Modal/Modal";
import { formatDate } from "../../Utils/Helpers/DateUtils";
const Status = () => {
  const [dateShow, setDateShow] = useState(new Date().toString().split(" "));
  const [firstCheckIn, setFirstCheckIn] = useState([]);
  const [isNextButtonDisable, setIsNextButtonDisable] = useState(false);
  const [CurrentDat, setCurrentDate] = useState(new Date());
  const [lastCheckOut, setLastCheckOut] = useState(false);

  const refreshInterval = 5000;
  useEffect(() => {
    sessionStorage.setItem("date", moment().format("YYYY-MM-DD"));
    employeeFirstCheckIn();
    let myInterval = setInterval(() => {
      employeeFirstCheckIn();
    }, refreshInterval);
    return () => clearInterval(myInterval);
  }, []);
  const employeeFirstCheckIn = async () => {
    const result = await GetEmployeeFirstCheckInTime(
      sessionStorage.getItem("date")
    );
    setFirstCheckIn(result);
  };
  const getPreviousDate = async () => {
    setIsNextButtonDisable(false);
    const currentDayInMilli = new Date(CurrentDat).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const previousDayInMilli = currentDayInMilli - oneDay;
    const previousDate = new Date(previousDayInMilli);
    setDateShow(previousDate.toString().split(" "));
    setCurrentDate(previousDate);
    const apiFormateDate = previousDate.toString().split(" ");
    const result = await GetEmployeeFirstCheckInTime(
      `${apiFormateDate[3]}-${month[apiFormateDate[1]]}-${apiFormateDate[2]}`
    );
    setFirstCheckIn(result);
    let updateDate = `${apiFormateDate[3]}-${month[apiFormateDate[1]]}-${
      apiFormateDate[2]
    }`;
    sessionStorage.setItem("date", updateDate);
    setLastCheckOut(previousDate < new Date());
  };
  const getNextDate = async () => {
    setLastCheckOut(false);
    const currentDayInMilli = new Date(CurrentDat).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const nextDayInMilli = currentDayInMilli + oneDay;
    const nextDate = new Date(nextDayInMilli);
    if (nextDate > new Date()) {
      setIsNextButtonDisable(true);
      return;
    }
    setDateShow(nextDate.toString().split(" "));
    setCurrentDate(nextDate);
    setLastCheckOut(formatDate(nextDate) < formatDate(new Date()));
    const apiFormateDate = nextDate.toString().split(" ");
    const result = await GetEmployeeFirstCheckInTime(
      `${apiFormateDate[3]}-${month[apiFormateDate[1]]}-${apiFormateDate[2]}`
    );
    setFirstCheckIn(result);
    let updateDate = `${apiFormateDate[3]}-${month[apiFormateDate[1]]}-${
      apiFormateDate[2]
    }`;
    sessionStorage.setItem("date", updateDate);
  };
  return (
    <div>
      <div className="container mb-3">
        <div className="row px-2">
          <div className="DateHeader">
            <div className="current-date alert alert-info" role="alert">
              <button
                onClick={getPreviousDate}
                className="btn btn-outline-info d-flex"
              >
                <AiOutlineArrowLeft />
              </button>
              <span className="currPrevNextDate">
                <b>
                  {`${week[dateShow[0]]}, ${dateShow[1]} ${dateShow[2]}, ${
                    dateShow[3]
                  }`}
                </b>
              </span>
              <button
                disabled={isNextButtonDisable}
                onClick={getNextDate}
                className="btn btn-outline-info d-flex"
              >
                <AiOutlineArrowRight />
              </button>
            </div>
          </div>
          <div className="row AttendanceContent">
            <div className="col-lg-4 col-md-12 col-sm-12 mb-3">
              <div className="d-flex justify-content-center gap-1">
                <h5 className="HeaderHeading">ATTENDANCE CHECK IN</h5>
                <div className="statusCount">
                  {firstCheckIn?.finalResult?.length}
                </div>
              </div>

              {firstCheckIn?.finalResult?.length === 0 ? (
                <div className="dataNotFound">Data Not Found !</div>
              ) : (
                <Accordion>
                  {firstCheckIn?.finalResult?.map((item, ind) => {
                    return (
                      <div className="checkin-accordian" key={ind}>
                        <CheckinAccordian item={item} ind={ind} />
                      </div>
                    );
                  })}
                </Accordion>
              )}
            </div>
            <CheckOutDetails
              firstCheckIn={firstCheckIn}
              lastCheckOut={lastCheckOut}
            />
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="d-flex justify-content-center gap-1">
                <h5 className="HeaderHeading">ABSENT</h5>
                <div className="statusCount">
                  {firstCheckIn?.absentEmp?.length}
                </div>
              </div>
              {firstCheckIn?.absentEmp?.length === 0 ? (
                <div className="dataNotFound">Data Not Found !</div>
              ) : (
                firstCheckIn?.absentEmp?.map((item, ind) => {
                  let wfh_status;
                  if (item?.wfh_status) {
                    wfh_status = true;
                  } else {
                    wfh_status = false;
                  }
                  return (
                    <div
                      className={wfh_status ? "StatusYellow" : "StatusGrey"}
                      key={ind}
                    >
                      <div
                        className={wfh_status ? "statusYellow" : "statusGrey"}
                      >
                        <div className="statusEmployeName increase-width">
                          {`${item?.first_name} ${item?.last_name} (${item?.emp_id})`}
                        </div>
                        <div>
                          <PopupModal
                            empID={item?.emp_id}
                            wfh_status={wfh_status}
                            onSuccess={employeeFirstCheckIn}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Status;
