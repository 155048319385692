import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PreviewModal from "../Attendances/Modals/Preview/Preview";
import { ToastFailure, ToastSuccess } from "../../Utils/Toast/ToastMsg";
import Select from "react-select";
import axios from "axios";
import "./report.style.scss";
import { formatDate } from "../../Utils/Helpers/DateUtils";
import Loader from "../../Utils/Loader/Loader";
export default function Reports() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [openPreviewModal, setOpenPreviewModal] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUserList();
  }, []);

  const getUserList = async () => {
    await axios
      .get(`${process.env.REACT_APP_API}/api/report/v2/employeesName`)
      .then((response) => {
        let updateRes = response?.data?.data;
        updateRes.sort((a, b) => {
          return a.first_name.localeCompare(b.first_name);
        });
        updateRes.unshift({
          employee_id: "",
          first_name: "All",
          last_name: "Employees",
        });
        setEmployeesData(updateRes ?? []);
      });
  };
  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  // export data
  const handleExport = async () => {
    if (startDate > endDate) {
      ToastFailure("Please enter valid start or end date.");
      setSelectedReport(null);
    } else if (startDate && endDate) {
      if (selectedOption?.value) {
        setIsLoading(true);
        try {
          await axios
            .get(
              `${process.env.REACT_APP_API}/api/report/v2/employeesAttendance?from=${startDate}&to=${endDate}&empId=${selectedOption?.value}`
            )
            .then((response) => {
              ToastSuccess("Success.");
              window.open(
                `${process.env.REACT_APP_API}/${response?.data?.url}`,
                "_self"
              );
              setIsLoading(false);
              setSelectedReport(null);
            });
        } catch (error) {
          ToastFailure(error?.response?.data?.message);
          setSelectedReport(null);
          setIsLoading(false);
        }
      } else {
        try {
          setIsLoading(true);
          await axios
            .get(
              `${process.env.REACT_APP_API}/api/report/v2/employeesAttendance?from=${startDate}&to=${endDate}`
            )
            .then((response) => {
              ToastSuccess("Success.");
              window.open(
                `${process.env.REACT_APP_API}/${response?.data?.url}`,
                "_self"
              );
              setIsLoading(false);
              setSelectedReport(null);
            });
        } catch (error) {
          ToastFailure(error?.response?.data?.message);
          setSelectedReport(null);
          setIsLoading(false);
        }
      }
    } else {
      ToastFailure("Please select start date and end date.");
      setSelectedReport(null);
      setIsLoading(false);
    }
  };

  // Preview attendance
  const handlePreview = () => {
    if (startDate && !endDate) {
      setOpenPreviewModal(true);
    } else if (startDate > endDate) {
      ToastFailure("Please enter valid start or end date.");
      setSelectedReport(null);
      setIsLoading(false);
    } else if (startDate && endDate) {
      setOpenPreviewModal(true);
    } else {
      ToastFailure("Please select start date or end date.");
      setSelectedReport(null);
      setIsLoading(false);
    }
  };

  const handleDetailedReport = async()=>{
      if (startDate && endDate){
      setIsLoading(true);
      try {
        await axios
          .get(
            `${process.env.REACT_APP_API}/api/report/employeesAttendance?from=${startDate}&to=${endDate}`
          )
          .then((response) => {
            window.open(
              `${response?.config?.url}`,
              "_self"
            );
            ToastSuccess("Success.");
            setSelectedReport(null);
            setIsLoading(false);
          });
      } catch (error) {
        ToastFailure(error?.response?.data?.message);
        setSelectedReport(null);
      }
     } else {
      ToastFailure("Please select start date and end date.");
      setSelectedReport(null);
    }
  }
  const handleReport = async () => {
    let tempStartDate = formatDate(startDate)
      ? formatDate(startDate)
      : startDate;
    let tempEndDate = formatDate(endDate) ? formatDate(endDate) : endDate;
    if (startDate > endDate) {
      ToastFailure("Please enter valid start or end date.");
      setSelectedReport(null);
    } else if (startDate && endDate) {
      if (selectedOption?.value) {
        try {
          setIsLoading(true);
          await axios
            .get(
              `${process.env.REACT_APP_API}/api/report/v2/getEmployeeXlsxSheetReport?from=${tempStartDate}&to=${tempEndDate}&empId=${selectedOption?.value}`
            )
            .then((response) => {
              ToastSuccess("Success.");
              window.open(
                `${process.env.REACT_APP_API}/${response?.data?.url}`,
                "_self"
              );
              setIsLoading(false);
              setSelectedReport(null);
            });
        } catch (error) {
          ToastFailure(error?.response?.data?.message);
          setSelectedReport(null);
        }
      } else {
        try {
          setIsLoading(true);
          await axios
            .get(
              `${process.env.REACT_APP_API}/api/report/v2/getEmployeeXlsxSheetReport?from=${tempStartDate}&to=${tempEndDate}`
            )
            .then((response) => {
              ToastSuccess("Success.");
              window.open(
                `${process.env.REACT_APP_API}/${response?.data?.url}`,
                "_self"
              );
              setIsLoading(false);
              setSelectedReport(null);
            });
        } catch (error) {
          ToastFailure(error?.response?.data?.message);
          setSelectedReport(null);
        }
      }
    } else {
      ToastFailure("Please select start date and end date.");
      setSelectedReport(null);
    }
  };
  const selectReport = [
    { label: "Report-1", value: "report-1" },
    { label: "Report-2", value: "report-2" },
    { label: "Report-3", value: "report-3" },
  ];
  const handleReports = (report) => {
    setSelectedReport(report);
    if (report.value === "report-1") {
      handleExport();
    } else if (report.value === "report-2") {
      handleDetailedReport();
    } else if (report.value === "report-3") {
      handleReport();
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader startLoader={isLoading} />
      ) : (
        <div className="AccordionSection mt-4">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <span></span>
                <div className="AttendanceSection">
                  <div className="attendanceDiv">
                    <div className="DateSection">
                      <div className="DatePicker">
                        <div className="dateSec">
                          <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            placeholderText="Start-Date"
                          />
                        </div>

                        <div className="dateSec">
                          <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            minDate={startDate}
                            maxDate={new Date()}
                            placeholderText="End-Date"
                          />
                        </div>

                        <div className="selectDropdown">
                          <Select
                            placeholder="All Employees"
                            onChange={handleSelect}
                            options={employeesData.map((item) => ({
                              value: item?.employee_id,
                              label: `${item?.first_name} ${item?.last_name}`,
                            }))}
                          />
                        </div>

                        <div className="selectDropdown">
                          <Select
                            value={selectedReport}
                            placeholder="Select Report"
                            onChange={handleReports}
                            options={selectReport.map((item) => ({
                              value: item.value,
                              label: item.label,
                            }))}
                          />
                        </div>
                      </div>
                      <div className="reportDiv">
                        <div className="reportBtn">
                          <button
                            className="ReportBtn"
                            onClick={() => handlePreview()}
                          >
                            Preview
                          </button>
                        </div>
                        {/* <div className="reportBtn">
                        <button
                          className="ReportBtn"
                          onClick={() => handleExport()}
                        >
                          Report-1
                        </button>
                      </div> */}
                        {/* <div className="reportBtn">
                        <button
                          className="ReportBtn"
                          onClick={() => handleDetailedReport()}
                        >
                          Report-2
                        </button>
                      </div> */}
                        {/* <ExtendedReportThird
                        startDate={startDate}
                        endDate={endDate}
                        selectedOption={selectedOption}
                      /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {openPreviewModal && (
        <PreviewModal
          selectedOption={selectedOption}
          show={openPreviewModal}
          setShow={setOpenPreviewModal}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
}
