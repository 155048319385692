import React, { useEffect, useState } from "react";
import moment from "moment";
import "../../Pages/Attendances/AttendanceLog.scss";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router";

const CheckinAccordian = (props) => {
  const navigate = useNavigate();
  const { item, ind } = props;
  const checkOut = item?.checkout_times;
  const aboveNineHours = Number(item?.totalCheckIn?.toString()?.split(":")[0]);
  const gotoClickHandler = (e, data) => {
    e.stopPropagation();
    navigate(`/attendances/${data.emp_id}`);
  };
  const formattedCheckIn = moment(item?.first_checkin_time, "hh:mm A").format(
    "HH:mm"
  );
  return (
    <>
      <Accordion.Item
        key={ind}
        eventKey={ind}
        className={`mt-3 ${
          aboveNineHours >= 9 ? "StatusGreen" : "StatusSkyBlue"
        } ${formattedCheckIn > "10:10" && item?.warning > 3 && "StatusPayCut"}`}
      >
        <Accordion.Header>
          <div className="statusEmployeName ">
            {`${item?.first_name} ${item?.last_name}`}
          </div>
          <div className="d-flex flex-column">
            <span className="InTime goto d-flex flex-row">
              {formattedCheckIn > "10:10" && (
                <p
                  className={
                    formattedCheckIn > "10:10" && item?.warning > 3
                      ? "payCutWarning"
                      : "lateWarning"
                  }
                >
                  Late
                </p>
              )}
              {item?.first_checkin_time}{" "}
              <button
                className="btn-Goto goto"
                onClick={(e) => {
                  gotoClickHandler(e, item);
                }}
              >
                Goto
              </button>
            </span>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Check-In</th>
                <th>Check-Out</th>
              </tr>
            </thead>
            <tbody>
              {item?.checkin_times?.map((ele, ind) => {
                return (
                  <tr key={ind}>
                    <td>{ele?.split(" ")[1] + " " + ele?.split(" ")[2]}</td>
                    <td>
                      {(checkOut[ind]?.split(" ")[1] === undefined
                        ? "-- :"
                        : checkOut[ind]?.split(" ")[1]) +
                        " " +
                        (checkOut[ind]?.split(" ")[1] === undefined
                          ? "--"
                          : checkOut[ind]?.split(" ")[2])}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td>
                  <span>
                    <b>TotalIn : </b>
                    {item?.totalCheckIn === 0 ? "00:00" : item?.totalCheckIn}
                  </span>
                </td>
                <td>
                  <span>
                    <b>TotalOut : </b>
                    {item?.totalCheckOut === 0 ? "00:00" : item?.totalCheckOut}
                  </span>
                </td>
              </tr>
            </tfoot>
          </table>
        </Accordion.Body>
      </Accordion.Item>
    </>
  );
};

export default CheckinAccordian;
