import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { ToastFailure, ToastSuccess } from "../../../Utils/Toast/ToastMsg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

function EditLogsModal(props) {
  const { show, setShow, captureEditDetails, getDetails } = props;
  const { punch_timestamp, id } = captureEditDetails;
  const [logDate, setLogDate] = useState(new Date(punch_timestamp));

  const handleClose = () => setShow(false);

  const submitEdit = async () => {
    await axios
      .put(`${process.env.REACT_APP_API}/api/checkin`, {
        id: id,
        punch_timestamp: moment(logDate).format(),
      })
      .then((response) => {
        if (response?.data?.status == (200 || 201)) {
          ToastSuccess(response?.data?.msg);
          getDetails();
          handleClose();
        } else {
          ToastFailure("Something went wrong.");
        }
      })
      .catch((error) => {
        ToastFailure("Something went wrong.");
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="p-2">
            <label className="mb-2">Log Date & Time:</label>
            <DatePicker
              name="ticketAfterDate"
              showTimeSelect
              selected={logDate}
              onChange={(date) => {
                setLogDate(date);
              }}
              timeIntervals={5}
              dateFormat="dd/MM/y hh:mm aa"
              placeholderText="Select Time & Date"
              maxDate={new Date()}
              minTime={new Date().setHours(0, 0, 0, 0)}
              maxTime={
                moment(logDate).format("l") == moment(new Date()).format("l")
                  ? Date.now()
                  : moment(logDate).endOf("day").toDate()
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type="submit" variant="primary" onClick={() => submitEdit()}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditLogsModal;
