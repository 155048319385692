import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Edit.scss";
import axios from "axios";
import { ToastFailure, ToastSuccess } from "../../../../Utils/Toast/ToastMsg";

function EditModal(props) {
  const { show, setShow, captureEditDetails, getDetails } = props;
  const { employee_id, enabled, email } = captureEditDetails;

  const EditEventSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Email is required"),
  });

  const handleClose = () => setShow(false);

  const submitEdit = async (values) => {
    await axios
      .put(`${process.env.REACT_APP_API}/api/employee`, {
        email: values.email,
        enabled: values.enableStatus,
        employee_id,
      })
      .then((response) => {
        if (response?.data?.status == (200 || 201)) {
          ToastSuccess(response?.data?.msg);
          getDetails();
          handleClose();
        } else {
          ToastFailure("Something went wrong.");
        }
      })
      .catch((error) => {
        ToastFailure("Something went wrong.");
      });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Formik
          initialValues={{
            email: email ?? "",
            enableStatus: enabled ?? false,
          }}
          enableReinitialize={true}
          validationSchema={EditEventSchema}
          onSubmit={(values, { resetForm }) => {
            submitEdit(values);
          }}
        >
          {({
            errors,
            touched,
            handleChange,
            values,
            setFieldValue,
            dirty,
            isValid,
            resetForm,
          }) => (
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Edit User</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="editBody">
                  <div className="form-group mb_20">
                    <label for="exampleInputEmail1" className="mb_10">
                      Email address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                    />
                    {errors.email && touched.email ? (
                      <div className="errorMsgs">{errors.email}</div>
                    ) : null}
                  </div>
                  <div className="editContent form-check form-switch">
                    <label
                      className="form-check-label"
                      for="flexSwitchCheckChecked"
                    >
                      Status :
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckChecked"
                      value={values.enableStatus}
                      onChange={(e) => {
                        setFieldValue("enableStatus", e.target.checked);
                      }}
                      checked={values.enableStatus}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button type="button" variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="primary">
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default EditModal;
